<template>
  <div class="api-setting">
    <div class="header">
      <div class="titre">
        <h4>Gestion API token</h4>
      </div>
    </div>
    <hr />
    <h5>Votre token personnel API</h5>
    <div class="token">
      <b-form-input
        v-if="getApiTokenLoading"
        value="Chargement en cours... "
        disabled
      >
      </b-form-input>
      <b-form-input :value="getApiToken" v-else disabled></b-form-input>
      <div class="copie">
        <b-button variant="success" @click="copyTokenApi(getApiToken)">
          Copie
        </b-button>
      </div>
    </div>
    <div class="tokenbtn">
      <b-button @click="apiToken">Générer un nouveau Token</b-button>
      <b-modal
        id="modal-center"
        centered
        title="Générer un nouveau Token"
        no-close-on-backdrop
        ref="modal-center"
        :hide-footer="true"
      >
        <p class="my-4">
          {{ message }}
        </p>
        <div class="message">
          <div v-if="getApiTokenLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getApiTokenError" class="error">
            <ul v-if="Array.isArray(getApiTokenError)">
              <li v-for="(e, index) in getApiTokenError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getApiTokenError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('modal-center')"
            variant="outline-secondary"
          >
            Non
          </b-button>
          <b-button variant="success" type="submit" @click="addToken">
            Oui
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      initLoading: true,
      message: null
    }
  },

  methods: {
    ...mapActions(['generateApiToken', 'fetchApiToken']),
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    async addToken() {
      await this.generateApiToken()
      this.hideModal('modal-center')
    },
    copyTokenApi(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$bvToast.toast(text, {
        title: 'API Token copié',
        autoHideDelay: 2000,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        solid: true
      })
    },
    apiToken() {
      if (!this.getApiToken) {
        this.message = 'Générer un nouveau token, êtes-vous sûr ?'
      } else {
        this.message =
          'Générer un nouveau token effacera le token existant , êtes-vous sûr ?'
      }
      this.$refs['modal-center'].show()
    }
  },
  computed: {
    ...mapGetters(['getApiToken', 'getApiTokenLoading', 'getApiTokenError'])
  },
  async mounted() {
    await this.fetchApiToken()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.api-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .token {
    padding-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .copie {
    margin-left: 10px;
  }
  .tokenbtn {
    padding-top: 15px;
    .btn-secondary {
      color: #212529;
      font-size: 13px;
      background-color: #f8f9fa;
      border-color: #d6d8db;
    }
  }
  .form-control:disabled,
  .form-control[readonly] {
    max-width: 450px;
    font-size: 14px;
    background-color: #e9ecef;
    opacity: 1;
  }
  .form-group {
    margin-bottom: 0rem;
  }
}
</style>
